<template>
  <div class="col-8 mx-auto justify-content-center">
    <h1>Nowa brygada</h1>
    <form class="add-form bg-light border" @submit.prevent>
      <small>Wybierz pracownika</small>
      <v-select
        v-model="employee"
        :options="employees"
        placeholder="Wybierz pracownika"
      ></v-select>

      <button
        style="width: 100%"
        class="btn btn-info btn-sm mt-1"
        @click="addEmployeeToSquad()"
      >Dodaj pracownika do brygady</button>
      <hr>
      <small>Dodani pracownicy</small>
      <ul>
          <li v-for="man in addedEmployees" :key="man.id">
              {{man.label}}

              <button @click="deleteMan(man)">usuń</button>
          </li>
      </ul>
      <hr>
      <small>Nazwa brygady</small>
      <input
        v-model="title"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Nazwa"
      />
      <!-- <small>Numer seryjny urządzenia</small>
      <input
        v-model="serialNumber"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Numer seryjny urządzenia"
      />
      <small>Ilość czynnika</small>
      <input
        v-model="amountOfRefrigerant"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Ilość czynnika"
      />
      <small>Data montazu</small>
      <input
        v-model="date"
        type="date"
        class="form-control mt-1 mb-1"
        placeholder="Data montazu"
      /> -->
      <small>Uwagi</small>
      <!-- <textarea
        v-model="notice"
        type="text"
        class="form-control mb-1"
        placeholder="Uwagi"
      /> -->
      <vue-editor v-model="notice"></vue-editor>
      <button
        style="width: 100%; height: 65px"
        class="btn btn-info btn-sm mt-1"
        @click="addSquad()"
      >
        Dodaj brygadę
      </button>
    </form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      employee: "",
      addedEmployees: [],
      title: "",
      notice: "",
    };
  },
  computed: {
    ...mapState(["userProfile", "employees"]),
  },
  components: {
    VueEditor,
  },
  methods: {
    addEmployeeToSquad() {
        this.addedEmployees.push(this.employee)
        this.employee = ""
    },
    deleteMan(e){
        let index = this.addedEmployees.indexOf(e)
        if (index > -1) {
        this.addedEmployees.splice(index, 1);
}

        console.log(index)
    }
  },
};
</script>